import {useState} from 'react'
import {useLogin} from '../hooks/useLogin'

const Login = () =>{
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const {login, error, isLoading} = useLogin()

    const handleSubmit = async (e) =>{
        e.preventDefault()

        await login(username, password)
    }

    return(
        <div className='login-cont'>
            <form className='login' onSubmit={handleSubmit}>
                <h3>Log In:</h3>

                <label>Username:</label>
                <br></br>
                <input 
                    type='text'
                    onChange={(e) => setUsername(e.target.value)}
                    value ={username}
                />
                <br></br>
                <label>Password:</label>
                <br></br>
                <input 
                    type='password'
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                />
                <br></br>
                <button disabled={isLoading}>Log in:</button>
                {error && <div className='error'>{error}</div>}
            </form>
        </div>
    )
}

export default Login