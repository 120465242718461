import { useNavigate } from "react-router-dom"

import { ReactComponent as LiveIcon} from '../icons/live.svg'

function BetterNavBar(props){
    const navigate = useNavigate();
    function home(){
        navigate(`/`)
    }

    function live(){
        navigate(`/live`)
    }

    return(
        <nav className="better-navbar">
            < LiveIcon className="live-icon" onClick={live}/>
            <h1 className="title" onClick={home}>Is This Gay</h1>
            <ul className="better-navbar-nav">{ props.children }</ul>
        </nav>
    )
}

export default BetterNavBar