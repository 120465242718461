import { useNavigate } from "react-router-dom"

function HomeItem(props){
    const navigate = useNavigate();

    function gotoHome(){
        navigate(`/`)
    }

    return(
        <li className="nav-item">
            <a href="#" className="icon-button" onClick={gotoHome}>
                {props.icon}
            </a>
        </li>
    )
}

export default HomeItem