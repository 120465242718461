import { useEffect, useRef, useState } from "react"

function NavItem(props){

    const [open,setOpen] = useState(false);

    let menuRef = useRef();
    
    useEffect(()=>{
        let handler = (e) =>{
            if(menuRef.current && !menuRef.current.contains(e.target)){
                setOpen(false)
            }
        }

        document.addEventListener("mousedown", handler)

        return()=>{
            document.removeEventListener("mousedown",handler)
        }
    })

    return(
        <li className="nav-item" ref={menuRef}>
            <a href="#" className="icon-button" onClick={() => setOpen(!open)}>
                {props.icon}
            </a>

            {open && props.children}
        </li>
    )
}

export default NavItem