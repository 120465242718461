import { useState } from 'react'
import {useAuthContext} from "../hooks/useAuthContext"


const NewQuestion = () => {
    const {user, dispatch} = useAuthContext()
    const [question, setQuestion] = useState('')
    const [error, setError] = useState(null)

    const handleSubmit = async (e) => {
        e.preventDefault()

        const newPost = {
            content: question,
            likes: 0,
            dislikes: 0,
            username: user.username
        }

        const response = await fetch('https://www.isthisgay.gay:4001/api/posts/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                'Authorization' : `Bearer ${user.token}`
            },
            body: JSON.stringify(newPost)
        })
        const json  = await response.json();

        if(!response.ok){
            setError(json.error)
        }
        if(response.ok){
            setQuestion('')
            setError(null)
            console.log(json)
            dispatch({type: 'UPDATE_GAYPOINTS', payload: json.gayPoints})
            console.log("question added", json)
        }
    }

    return(
        <form className='create-new-question' onSubmit={handleSubmit}>
            {user && (<span className='gay-points'>GP: {user.gayPoints}</span>)}
            <h3>Add New Question:</h3>
            <textarea 
                type="text"
                className='add-question-textarea'
                onChange={(e) => setQuestion(e.target.value)}
                value={question}
            />
            <button className='question-submit'>Submit</button>
            {error && <div className='error'>{error}</div>}
        </form>
    )

}

export default NewQuestion