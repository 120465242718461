
import { ReactComponent as CogIcon } from '../icons/cog.svg';
import { ReactComponent as ChevronIcon } from '../icons/chevron.svg';
import { ReactComponent as PlusIcon } from '../icons/plus.svg';
import { ReactComponent as LogoutIcon } from '../icons/logout.svg';
import { ReactComponent as LoginIcon } from '../icons/login.svg';
import { ReactComponent as SignupIcon } from '../icons/signup.svg';
import { ReactComponent as ProfileIcon } from '../icons/profile.svg';

import {CSSTransition} from 'react-transition-group'

import { useAuthContext } from '../hooks/useAuthContext'
import { useNavigate } from "react-router-dom"
import { useLogout } from '../hooks/useLogout'


function DropDownMenu() {

    const navigate = useNavigate();
    const {user} = useAuthContext()
    const {logout} = useLogout()

    function gotoProfile(){
        navigate(`/profile/${user.username}`)
    }
    function gotoNewQuestion(){
        navigate(`/newQuestion`)
    }
    function gotoLogin(){
        navigate(`/login`)
    }
    function gotoLogout(){
        logout()
    }
    function gotoSignup(){
        navigate(`/signup`)
    }

    function DropdownItem(props){
        return(
            <a href="#" className="menu-item" onClick={props.func}>
                <span className="icon-button">{props.leftIcon}</span>

                {props.children}

                <span className="icon-right">{props.rightIcon}</span>
            </a>
        )
    }

    return(
        <div className="dropdown">
            {user && (<>
                <DropdownItem
                leftIcon={<ProfileIcon />}
                func={gotoProfile}>My Profile</DropdownItem>
            <DropdownItem 
                leftIcon={<PlusIcon />}
                rightIcon={<ChevronIcon />}
                func={gotoNewQuestion}
                >
                Add Question
            </DropdownItem>
            <DropdownItem
                leftIcon={<LogoutIcon/>}
                func={gotoLogout}
                >
                Logout
            </DropdownItem>
            </>)}
            {!user && (
                <>
                <DropdownItem
                    leftIcon={<LoginIcon/>}
                    func={gotoLogin}
                    >
                    Login
                </DropdownItem>
                <DropdownItem
                    leftIcon={<SignupIcon/>}
                    func={gotoSignup}
                    >
                    signup
                </DropdownItem>    
                </>
            )}
            
        </div>
    )
}

export default DropDownMenu;