import { useNavigate } from "react-router-dom"

const Comment = ({comment}) => {
    const navigate = useNavigate();

    const handleNav = () => {
        navigate(`/profile/${comment.username}`)
    }

    return(
        <div className="comment">
            <p className="comment-username" onClick={handleNav}>{comment.username}:</p>
            <br></br>
            <p className="comment-content">{comment.comment}</p>
            <button className="comment-report" >report</button>
        </div>
    )
}

export default Comment