import React, { createContext } from 'react';
import { io} from 'socket.io-client';

const socket = io('http://35.202.92.246:5000')
const SocketContext = createContext(socket);

const SocketProvider = ({ children }) => {
  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};
export { SocketContext, SocketProvider };