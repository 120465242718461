import {useParams} from 'react-router-dom'
import { useEffect, useState } from 'react'
import QuestionDetailsMini from '../components/QuestionDetailsMini';

const Profile = () => {
    const {username} = useParams();
    const [questions, setQuestions] = useState([]);
    const [page,setPage] = useState(0);

    useEffect(()=>{
        const fetchQuestions = async () => {
            if(username){
                console.log(page)
                const response = await fetch(`https://www.isthisgay.gay:4001/api/posts/${username}/${page}`, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json'
                    }
                })
                const json = await response.json();

                if(response.ok){
                    if(json.length===0)
                        setPage(page-1)
                    else
                        setQuestions([...json])
                }
            }
        }
        fetchQuestions();
    }, [username,page])
    
    const nextPage = () => {
        setPage(page+1)
    }
    const lastPage = () => {
        if(page>0)
            setPage(page-1)
    }

    return(
        <>
        <p>Profile</p>
        {
            username && <p>{username}</p>
        }
        {
            questions.length>0 && questions.map((question)=> (
                <QuestionDetailsMini key={question._id} question={question} />
            ))
        }
        <div className='page-selector'>
            <button className='last-page' onClick={lastPage}>last</button>
            <p>---{page}---</p>
            <button className='next-page' onClick={nextPage}>next</button>
        </div>
        </>
    )
}

export default Profile