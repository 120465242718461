import {useState} from 'react'
import { useSignup } from '../hooks/useSignup'

const Signup = () =>{
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const {signup, error, isLoading} = useSignup()

    const handleSubmit = async (e) => {
        e.preventDefault()

        await signup(username, password)
    }

    return(
        <div className='login-cont'>
            <form className='login' onSubmit={handleSubmit}>
                <h3>Sign Up</h3>

                <label>Username:</label>
                <br></br>
                <input 
                    type="text"
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                />
                <br></br>
                <label>Password:</label>
                <br></br>
                <input 
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                />
                <br></br>
                <button disabled={isLoading}>Sign Up</button>
                {error && <div className='error'>{error}</div>}
            </form>
        </div>
    )
}

export default Signup