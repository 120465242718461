import { createContext, useReducer } from "react";

export const QuestionsContext = createContext()

export const questionReducer = (state, action) => {
    let postId;
    switch(action.type){
        case 'SET_QUESTIONS':
            return {questions : action.payload}
        case 'UPDATE_QUESTION':
            console.log(action.payload._id)
            postId = state.questions.findIndex(question => question._id === action.payload._id)
            action.payload.rated = state.questions[postId].rated;
            action.payload.answered = true;
            state.questions[postId] = action.payload;
            return{questions : state.questions}
        case 'UPDATE_QUESTION_RATED':
            console.log(action.payload._id)
            postId = state.questions.findIndex(question => question._id === action.payload._id)
            action.payload.answered = state.questions[postId].answered;
            action.payload.rated = true;
            state.questions[postId] = action.payload;
            return{questions : state.questions}
        case 'DELETE_QUESTION':
            const postIndex = state.questions.findIndex(question => question._id === action.payload._id)
            console.log(postIndex)
            if(postIndex===-1)
                return state
            state.questions.splice(postIndex,1)
            console.log(state.questions)
            return{questions: state.questions}
        default:
            return state

    }
}

export const QuestionsContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(questionReducer, {
        questions: null
    })

    return(
        <QuestionsContext.Provider value={{...state, dispatch}}>
            { children }
        </QuestionsContext.Provider>
    )
}

