import { useNavigate } from "react-router-dom"

const QuestionDetailsMini = ({question}) => {
    const navigate = useNavigate();
    
    const handleClick = () => {
        navigate(`/post/${question._id}`)
    }

    return(
        <>
        {console.log(question)}
        <div className="mini-question-details" onClick={handleClick}>
            <h4 className="question-username">{question.username}</h4>
            <p className="mini-text-container">{question.content}</p>
        </div>
        </>
    )
}

export default QuestionDetailsMini;