import { useQuestionsContext } from "../hooks/useQuestionsContext";
import { useAuthContext } from "../hooks/useAuthContext";
import { useLogout } from '../hooks/useLogout'
import { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom"

import { ReactComponent as BackIcon} from '../icons/back.svg'
import { ReactComponent as NextIcon} from '../icons/next.svg'
import { ReactComponent as LikeIcon} from '../icons/like.svg'
import { ReactComponent as DislikeIcon} from '../icons/dislike.svg'
import { ReactComponent as PrideIcon} from '../icons/pride.svg'

const QuestionDetails = ({question, handleLast, handleNext}) => {
    const {user, dispatch:userDispatch} = useAuthContext()
    const {logout} = useLogout()
    const {questions, dispatch} = useQuestionsContext();

    const [disable, setDisable] = useState(false)
    const [answered, setAnswered] = useState(false)
    const [rated, setRated] = useState(false)
    const [reported,setReported] = useState(false)

    const navigate = useNavigate();

    useEffect(()=>{
        if(question.answered){
            setDisable(true)
        }
        if(question.rated){
            setRated(true)
        }
    },[question])

    const handleLike = async () => {
        if(user){
            setDisable(true)
            const response = await fetch(`https://www.isthisgay.gay:4001/api/posts/${question._id}/like`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json();
            
            const {post,gayPoints} = json;
            if(response.ok){
                setAnswered(true)
                userDispatch({type: 'UPDATE_GAYPOINTS', payload: gayPoints})
                //dispatch({type: 'DELETE_QUESTION', payload: json});
                dispatch({type: 'UPDATE_QUESTION', payload: post})
                //console.log(questions)
            }else{
                if(response.status===401){
                    if(response.statusText==='Unauthorized'){
                        logout()
                    }
                }
            }
        }
    }

    const handleDislike = async () => {
        if(user){
            setDisable(true)
            const response = await fetch(`https://www.isthisgay.gay:4001/api/posts/${question._id}/dislike`, {
                method: 'PUT',
                headers: {
                    "Authorization": `Bearer ${user.token}`
                }
            })
            const json = await response.json();
            const {post,gayPoints} = json;
            if(response.ok){
                setAnswered(true)
                userDispatch({type: 'UPDATE_GAYPOINTS', payload: gayPoints})
                dispatch({type: 'UPDATE_QUESTION', payload: post})
                //dispatch({type: 'DELETE_QUESTION', payload: json});
                //console.log(questions)
            }else{
                if(response.status===401){
                    if(response.statusText==='Unauthorized'){
                        logout()
                    }
                }
            }
        }
    }

    const handleReport = async () => {
        if(user){
            setReported(true)
            const response = await fetch(`https://www.isthisgay.gay:4001/api/posts/${question._id}/report`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json();

            if(response.ok){
                dispatch({type: 'DELETE_QUESTION', payload: json});
                //console.log(questions)
            }else{
                if(response.status===401){
                    if(response.statusText==='Unauthorized'){
                        logout()
                    }
                }
            }
        }
    }

    const handleFunny = async () => {
        if(user){
            setRated(true)
            const response = await fetch(`https://www.isthisgay.gay:4001/api/posts/${question._id}/funny`, {
                method: 'PUT',
                headers: {
                    "Authorization": `Bearer ${user.token}`
                }
            })
            const json = await response.json();

            if(response.ok){
                //dispatch({type: 'DELETE_QUESTION', payload: json});
                //console.log(questions)
                dispatch({type: 'UPDATE_QUESTION_RATED', payload: json})
            }else{
                if(response.status===401){
                    if(response.statusText==='Unauthorized'){
                        logout()
                    }
                }
            }
        }
    }

    const handleUnfunny = async () => {
        if(user){
            setRated(true)
            const response = await fetch(`https://www.isthisgay.gay:4001/api/posts/${question._id}/unfunny`, {
                method: 'PUT',
                headers: {
                    "Authorization": `Bearer ${user.token}`
                }
            })
            const json = await response.json();

            if(response.ok){
                dispatch({type: 'UPDATE_QUESTION_RATED', payload: json})
                //dispatch({type: 'DELETE_QUESTION', payload: json});
                //console.log(questions)
            }else{
                if(response.status===401){
                    if(response.statusText==='Unauthorized'){
                        logout()
                    }
                }
            }
        }
    }

    const handleUsername = () => {
        navigate(`/profile/${question.username}`)
    }

    return(
        <div className="question-details">
            {question.username &&<h4 className="question-username" onClick={handleUsername}>{question.username}:</h4>}
            <p className="text-container">{question.content}</p>
            <div className="control-container">
                {handleLast && <button className="last-question" onClick={handleLast}>{<BackIcon className="back-icon"/>}</button>}
                <div className="like-dislike">
                    <button className="funny-unfunny-btn" onClick={handleFunny} disabled={rated}><LikeIcon className="like-icon"/><br/>{question.funny}</button>
                    <button className="like-dislike-btn" onClick={handleLike} disabled={disable}>{<PrideIcon className="pride-icon"/>}<br/>{disable ? question.likes : "??"}</button>   
                    <button className="like-dislike-btn" onClick={handleDislike} disabled={disable}>🍆🚫<br />{disable ? question.dislikes : "??"}</button>
                    <button className="funny-unfunny-btn" onClick={handleUnfunny} disabled={rated}>{<DislikeIcon className="dislike-icon"/>}<br />{question.unfunny}</button>
                    <br />
                    {/*<button className="report-btn" onClick={handleReport} disabled={reported}>report</button> */}
                </div>
                {handleLast && <button className="next-question" onClick={handleNext}><NextIcon className="next-icon"/></button>}    
            </div>
        </div>
    )

}

export default QuestionDetails