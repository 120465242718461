import {createContext, useEffect, useReducer} from 'react'

export const LoggedInContext = createContext();

export const LoggedInReducer = (state, action) => {
    switch(action.type){
        case 'LOGIN':
            return {loggedIn: true}
        case 'LOGOUT':
            return {loggedIn: false}
        case 'UPDATE':
            return {loggedIn: action.payload}
    }
}

export const LoggedInContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(LoggedInReducer, {
        loggedIn: null
    })

    useEffect(() => {
        const loggedIn = JSON.parse(localStorage.getItem('loggedIn'))

        if(loggedIn){
            dispatch({type: 'UPDATE', payload: loggedIn})
        }else{
            dispatch({type: 'LOGOUT', payload: null})
        }
    },[])

    return(
        <LoggedInContext.Provider value={{...state, dispatch}}>
            {children}
        </LoggedInContext.Provider>
    )
}