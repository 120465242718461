import { useAuthContext } from "./useAuthContext";
import { useLoggedInContext } from "./useLoggedInContext";

export const useLogout = () =>{
    const {dispatch} = useAuthContext()
    const {dispatch:loggedInDispatch} = useLoggedInContext();

    const logout = () => {
        //remove use from storage
        localStorage.removeItem('user')
        localStorage.setItem('loggedIn', false)

        //dispatch logout action
        dispatch({type: 'LOGOUT'})
        loggedInDispatch({type: 'LOGOUT'})
    }

    return{logout}
}