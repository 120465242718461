import {useState} from "react";
import {useAuthContext} from './useAuthContext'
import { useLoggedInContext } from "./useLoggedInContext";

export const useSignup = () => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const {dispatch} = useAuthContext();
    const {dispatch:loggedInDispatch} = useLoggedInContext();

    const signup = async (username, password) => {
        setIsLoading(true)
        setError(null)
        
        const response = await fetch('https://www.isthisgay.gay:4001/api/user/signup', {
            method: 'POST',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({username, password})
        })
        const json = await response.json();

        if(!response.ok){
            setIsLoading(false)
            setError(json.error)
        }
        if(response.ok){
            //save to local storage

            localStorage.setItem('user', JSON.stringify(json))
            localStorage.setItem('loggedIn', true)

            //update AuthContext
            dispatch({type: 'LOGIN', payload: json})
            loggedInDispatch({type: 'LOGIN', payload: null})

            setIsLoading(false)
        }
    }
    return{signup, isLoading, error}   
}