import { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom'
import QuestionDetails from '../components/QuestionDetails';
import {useAuthContext} from "../hooks/useAuthContext"
import Comment from "../components/Comment";

const Post = () => {
    const {id} = useParams();
    const {user} = useAuthContext()
    const [question,setQuestion] = useState()
    const [loadedComments,setLoadedComments] = useState(false)
    const [newComment, setNewComment] = useState('')
    const [comments, setComments] = useState([])
    const [error,setError] = useState(null)

    useEffect(()=>{
        const fetchQuestion = async () => {

            const response = await fetch(`https://www.isthisgay.gay:4001/api/posts/${id}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json'
                }
            })
            const json = await response.json();

            if(response.ok){
                console.log(json)
                setQuestion(json)
            }

        }
        fetchQuestion();
    },[id])

    const getComments = async() => {
        if(!loadedComments){
            setLoadedComments(true)
        }

        const response = await fetch(`https://www.isthisgay.gay:4001/api/posts/${id}/comments`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json'
            }
        })
        const json = await response.json();

        if(response.ok){
            console.log(json)
            setComments(json)
        }
    }

    const onComment = async () => {
        //add check for default
        if(user){
            const response = await fetch(`https://www.isthisgay.gay:4001/api/posts/${id}/comment`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                    'Authorization' : `Bearer ${user.token}`
                },
                body: JSON.stringify({comment: newComment})
            })
            const json  = await response.json();

            if(!response.ok){
                console.log("fuck" +json.message)
            }
            if(response.ok){
                console.log(json)
                setComments([json, ...comments])
                setNewComment('')
            }
        }else{
            setError("must be logged in to comment")
        }
    }

    return(
        <>
        <div className='single-post'>
            {
                question && <QuestionDetails key={question._id} question={question}/>
            }
        </div>
        <textarea className="comment-input" type="text" onChange={(e) => setNewComment(e.target.value)} value={newComment} />
        <button className="add-comment" onClick={onComment}>add</button>
        { error && <div className="comment-error">{error}</div>}
        <div className="comment-section">
        {
            question && !loadedComments && getComments()
        }
        {   
            comments.length>0 && comments.map((comment)=> (
                <Comment key={comment._id} comment={comment}/>
            ))
        }
        {
            comments.length===0 && 
            <p>no comments yet...</p>
        }
        </div>
        </>
    )
}

export default Post