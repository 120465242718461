import { LoggedInContext } from "../context/LoggedInContext";
import { useContext } from "react";

export const useLoggedInContext = () => {
    const context = useContext(LoggedInContext)

    if(!context){
        throw Error('useLoggedInContext must be inside tree')
    }

    return context
}