import Home from "./pages/Home"
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import LiveStream from "./pages/LiveStream";
import Admin from "./pages/Admin";
import NavBar from "./components/NavBar"
import NewQuestion from "./pages/NewQuestion";
import Profile from './pages/Profile';
import Post from "./pages/Post";
import BetterNavBar from "./components/BetterNavBar";
import NavItem from "./components/NavItem";
import DropDownMenu from "./components/DropdownMenu";
import HomeItem from "./components/HomeItem";

import { ReactComponent as BellIcon } from './icons/bell.svg';
import { ReactComponent as MessengerIcon } from './icons/messenger.svg';
import { ReactComponent as CaretIcon } from './icons/caret.svg';
import { ReactComponent as CogIcon } from './icons/cog.svg';
import { ReactComponent as ChevronIcon } from './icons/chevron.svg';
import { ReactComponent as ArrowIcon } from './icons/arrow.svg';
import { ReactComponent as BoltIcon } from './icons/bolt.svg';
import { ReactComponent as HomeIcon} from './icons/home.svg'

import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom'

import { useAuthContext } from "./hooks/useAuthContext";
import { useLoggedInContext } from "./hooks/useLoggedInContext";

function App() {
  const {user} = useAuthContext()
  const {loggedIn} = useLoggedInContext();
  return (
    <>
      <BrowserRouter>
        <BetterNavBar>
          {/*<NavItem icon={<PlusIcon />}/>
          <NavItem icon={<BellIcon />}/>
          <NavItem icon={<MessengerIcon />}/>*/}
          <HomeItem icon={<HomeIcon />}/>
          <NavItem icon={<CaretIcon />}>
            <DropDownMenu />
          </NavItem>

        </BetterNavBar>
        {/*loggedIn!=null && <NavBar />*/}
          <div className="pages">
            <Routes>
              <Route 
                path="/"
                element= {loggedIn!=null &&<Home />}
              />
              <Route
                path="/newQuestion"
                element={user ? <NewQuestion /> : <Navigate to="/" />}
              />
              <Route 
                path="/signup"
                element={!user ? <Signup /> : <Navigate to="/" />}
              />
              <Route 
                path="/login"
                element={!user ? <Login /> : <Navigate to="/" />}
              />
              <Route 
                path="/live"
                element={<LiveStream/>}
              />
              <Route 
                path="/admin"
                element={user ? ( user.admin ? <Admin /> : <Navigate to="/" />) : <Navigate to="/" />}
              />
              <Route
                path="/profile/:username"
                element={<Profile />}
              />
              <Route
                path="/post/:id"
                element={<Post />}
              />
            </Routes>
          </div>
      </BrowserRouter>
    </>
  );
}

export default App;
