import { useQuestionsContext } from "../hooks/useQuestionsContext";
import { useEffect, useState} from 'react'
import {useAuthContext} from "../hooks/useAuthContext"

//components
import QuestionDetails from '../components/QuestionDetails';
import Comment from "../components/Comment";

const Home = () =>{
    const {questions, dispatch} = useQuestionsContext();
    const {user} = useAuthContext()
    const [questionOn, setQuestionOn] = useState(0);
    const [loadedComments,setLoadedComments] = useState(false)
    const [newComment, setNewComment] = useState('')
    const [comments, setComments] = useState([])
    const [error,setError] = useState(null)

    useEffect(() => {
        fetchQuestions();
    },[user])

    const fetchQuestions = async () => {
        //console.log(user)
        //setQuestionOn(0)
        setQuestionOn(0)
        if(user){
            const response = await fetch('https://www.isthisgay.gay:4001/api/posts/', {
                method: 'GET',
                headers: { 'Content-Type': 'application/json',
                    'Authorization' : `Bearer ${user.token}`
                }
            })
            const json = await response.json();

            if(response.ok){
                dispatch({type: 'SET_QUESTIONS', payload: json})
                console.log(json)
            }
            //getComments(0);
        }else{
            //console.log(response)
            const response = await fetch('https://www.isthisgay.gay:4001/api/posts/')
            const json = await response.json();

            if(response.ok){
                dispatch({type: 'SET_QUESTIONS', payload: json})
            }
        }
    }

    const getComments = async(index) => {
        if(!loadedComments){
            setLoadedComments(true)
        }

        const currentPostId = questions[index]._id;
        const response = await fetch(`https://www.isthisgay.gay:4001/api/posts/${currentPostId}/comments`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json'
            }
        })
        const json = await response.json();

        if(response.ok){
            console.log(json)
            setComments(json)
        }
    }

    const handleNext = () => {
        let t = questionOn+1;
        if(questionOn<questions.length-1){
            setComments([])
            setQuestionOn(t)
            getComments(t)
            setNewComment('')
        }else{
            fetchQuestions()
            console.log('newQuestions')
        }
        
    }
    const handleLast = () => {
        let t = questionOn-1
        if(questionOn>0){
            setComments([])
            setQuestionOn(t)
            getComments(t)
            setNewComment('')
        }
        
    }

    const onComment = async () => {
        //add check for default
        if(user){
            const currentPostId = questions[questionOn]._id;

            const response = await fetch(`https://www.isthisgay.gay:4001/api/posts/${currentPostId}/comment`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                    'Authorization' : `Bearer ${user.token}`
                },
                body: JSON.stringify({comment: newComment})
            })
            const json  = await response.json();

            if(!response.ok){
                console.log("fuck" +json.message)
            }
            if(response.ok){
                console.log(json)
                setComments([json, ...comments])
                setNewComment('')
            }
        }else{
            setError("must be logged in to comment")
        }
    }

    return(
        <div className="home">
            <div className="questions">
                {/*questions && questions.map((question)=> (
                    <QuestionDetails key={question._id} question={question} />
                ))*/}
                {   questions && questions.length>0 &&<div className="question-container">
                        <QuestionDetails key={questions[questionOn]._id} question={questions[questionOn]} handleLast={handleLast} handleNext={handleNext}/>
                        {/* 
                        <div className="last-next">
                            <button className="last-question" >last question</button>
                            <button className="next-question" onClick={handleNext}>next question</button>
                        </div>
                        */}
                    </div>
                }
                {questions && questions.length>0 && <div className="comment-container">
                    <textarea className="comment-input" type="text" onChange={(e) => setNewComment(e.target.value)} value={newComment} />
                    <button className="add-comment" onClick={onComment}>add</button>
                    { error && <div className="comment-error">{error}</div>}
                    <div className="comment-section">
                        {
                            questions && !loadedComments && getComments(questionOn)
                        }
                        {   
                            comments.length>0 && comments.map((comment)=> (
                                <Comment key={comment._id} comment={comment}/>
                            ))
                        }
                        {
                            comments.length===0 && 
                            <p>no comments yet...</p>
                        }
                    </div>
                </div>}
                {
                    questions && questions.length==0 && <div className="answered-all">you answered all the questions</div>
                }
            </div>
        </div>
    )
}

export default Home