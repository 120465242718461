import { useState, useEffect, useContext, useRef} from "react"
import { SocketContext } from "../context/SocketContext"
import { useAuthContext } from '../hooks/useAuthContext'

const LiveStream = () => {
    const socket = useContext(SocketContext)
    const {user} = useAuthContext()

    const [error,setError] = useState('')
    const [messages, setMessages] = useState([]);
    const [messageInput, setMessageInput] = useState('')
    const textAreaRef = useRef(null)
    
    useEffect(() =>{
        socket.on('message', (message) => {
            setMessages([...messages, message])
        })

        const textArea = textAreaRef.current
        if(textArea){
            textArea.scrollTop = textArea.scrollHeight;
        }

        /*return () => {
            socket.off('message')
        }*/

    }, [messages])

    const sendMessage = () => {
        if(user){
            socket.emit('message', {
                username: user.username,
                text: messageInput
            })
            setMessageInput('')
        }else{
            setError('must be logged in to send messages')
        }
    }

    return(
        <div className="live-chat-container">
            <div className="messages">
                {/*messages.map((message, index) => (
                    <div key={index}>{message.username}: {message.text}</div>
                ))*/}
                <textarea className="live-chat" ref={textAreaRef} readOnly value={messages.map((message) => `${message.username} : ${message.text}`).join('\n')} />
                <br/>
                <div className="live-input-container">
                    <input className="live-input" type="text" value={messageInput} placeholder="new message"
                    onChange={(e) => setMessageInput(e.target.value)}/>

                    <button className='live-btn' onClick={sendMessage}>SEND</button>
                    <br/>
                    {<p className="error">{error}</p>}
                </div>
            </div>
        </div>
        )
}

export default LiveStream